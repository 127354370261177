<template>
  <moe-page title="供应商账单" class="supplier-billing-form">
    <moe-card class="mb-20" :autoHeight="true">
      <div class="df">
        <div style="width: 50%;" class="mr-20">
          <div class="fwb font-16">供应商信息</div>
          <moe-describe-list title="" :col="1">
            <moe-describe-item label="账单编号"> {{ $route.query.no }}</moe-describe-item>
            <moe-describe-item label="时间">{{ $moe_time.getTimeStamp(vendorBillDetail.time, 'YYYY年MM月') }} </moe-describe-item>
            <moe-describe-item label="供应商名称">{{ vendorBillDetail.name }}</moe-describe-item>
          </moe-describe-list>
        </div>
        <div style="width: 50%;" class="mr-20">
            <div class="fwb font-16">销售金额</div>
            <moe-describe-list title="" :col="1">
              <moe-describe-item label="运费"> ￥{{ vendorBillDetail.freight }} </moe-describe-item>
              <moe-describe-item label="商品总额">￥{{ vendorBillDetail.amount }}</moe-describe-item>
              <moe-describe-item label="总计">￥{{ vendorBillDetail.freight + vendorBillDetail.amount }}</moe-describe-item>
            </moe-describe-list>
          </div>
      </div>
      <!-- 筛选信息 -->
      <moe-table ref="orderTable" url="/shop/vendor/billDetailOrderList" :params="billDetailOrderListParams" :number-show="false" class="content">
            <el-table-column type="" width="30" />
            <el-table-column width="1" class="box">
              <template slot-scope="{ row }">
                <div class="orderNum df tal">
                  <span class="ml-70">订单编号：{{ row.orderNo }}</span>
                  <span class="ml-30">创建时间：{{ $moe_time.getTimeStamp(row.createTime) }}</span>
                  <span class="ml-30"><moe-icon name="avatar" class="font-16"></moe-icon></span>
                  <span class="ml-5">{{ row.name }}</span>
                  <span class="ml-30 df aic"><i class="font-20 el-icon-phone"></i>{{ row.phone }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="商品信息" min-width="150">
              <template slot-scope="{ row }">
                <div v-for="(item, index) in row.orderItemList" :key="index" class="df aic jcsb listBox">
                  <div class="df">
                    <moe-image :src="item.coverUrl" class="imgSty mr-10"/>
                    <div class="tal df1">
                      <p class="fwb">{{ item.goodsName }}</p>
                      <div class="df aic">
                        <p class="font-12 color-info">{{ item.goodsItemValue }}</p>
                        <p v-if="item.fromGift" class="b-primary color-primary w-35 h-25 lh-25 tac ml-10">赠品</p>
                      </div>
                      <p class="color-danger"><span class="fwb">￥{{ item.payPrice }}</span> </p>
                    </div>
                  </div>
                </div>

              </template>
            </el-table-column>
            <el-table-column label="数量" min-width="100">
              <template slot-scope="{ row }">
                <div v-for="(item, index) in row.orderItemList" :key="index" class="df aic jcc pt-10 listBox">
                  <div class="df">
                    {{ item.number }}
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="订单状态" min-width="100">
              <template slot-scope="{ row }">
                <div v-for="(item, index) in row.orderItemList" :key="index" class="df aic jcc pt-10 listBox">
                  <div>{{ $moe_format.getPayStatus(item.payStatus) }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="商品金额" min-width="100">
              <template slot-scope="{ row }">
                <div v-for="(item, index) in row.orderItemList" :key="index" class="df aic jcc pt-10 listBox">
                  <div class="df">
                    {{ item.totalPrice }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="freight" label="运费" min-width="100" />
          </moe-table>
    </moe-card>
  </moe-page>
</template>

<script>
export default {
  name: 'BillingManageSupplierBillingDetail',
  data() {
    return {
      billDetailOrderListParams: {
        no: this.$route.query.no,
        pageNum: 1,
        pageSize: 10,
      },
      vendorBillDetail: {},
    }
  },
  mounted() {
    //获取商品信息
    this.getVendorBillDetail();
  },
  methods: {
    /** 根据账单编号获取账单详情 */
    async getVendorBillDetail() {
      // 获取商品ID
      const no = this.$route.query.no;
      // 查看商品详情no
      const { code, result, message } = await this.$moe_api.SUPPLIERBILLING_API.getVendorBillDetail({ no });
      if (code === 200) {
        this.vendorBillDetail = result
      } else {
        this.$moe_msg.error(message);
      }
    },
  },
}
</script>

<style lang="scss">
.supplier-billing-form {
  .el-table--fit {
    margin: 0;
    width: 100%;
  }

  .orderNum {
    width: 100vw;
    background: rgba(223, 166, 99, 0.05);
    position: absolute;
    top: 0;
    left: -55px;
    z-index: 1;
    height: 50px;
    line-height: 50px;
  }

  .listBox {
    height: 100px;
    padding-top: 20px;
    // border-bottom: 1px solid red;
  }

  .imgSty {
    width: 80px;
    height: 80px;
    overflow: hidden;
    display: block;
  }

  .mesStyTac {
    justify-content: center;
  }

  .content {
    .el-table--medium {
      .el-table__cell {
        padding-top: 50px;
        padding-bottom: 20px;
      }
    }

    .moe-table__head {
      .el-table__cell {
        padding: 10px 0;
      }
    }

    .el-table__row td:nth-child(1) .cell {
      position: absolute !important;
      top: 12px !important;
      left: 50%;
      transform: translateX(-50%);
      z-index: 999;
    }
  }

  .moe-describe-list {
    padding: 0;
  }

  .moe-describe-item {
    padding: 0;
  }
}</style>